import homeHero from "./home_hero.png";
import homeCards from "./home_cards.png";
import feature from "./feature.png";
import expo from "./expo.png";
import expo02 from "./expo02.png";
import javascript from "./javascript.png";
import react from "./react.png";
import scene from "./scene.png";
import mockup from "./mockup.png";
import google from "./google.png";
import apple from "./apple.png";
import lsewhite from "./g33.png"
import bnp from "./bnp.png";
import atlanpole from "./Atlanpole.png";
import pepite from "./pepite.png";
import cantine from "./Cantine.png";
import nu from "./NU.png";
import centrale from "./Centrale.png";
import vieetudiante from "./vieetudiante.png"


export default {
  homeHero,
  homeCards,
  feature,
  expo,
  expo02,
  javascript,
  react,
  scene,
  mockup,
  google,
  apple,
  lsewhite,
  bnp,
  atlanpole,
  pepite,
  cantine,
  nu,
  centrale,
  vieetudiante
};
