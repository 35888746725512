import React, { useRef, useEffect } from 'react';
import styles from '../styles/global';
import assets from '../assets';

const Partners = () => {
    const partners = [
        { id: 1, src: assets.bnp },
        { id: 2, src: assets.atlanpole },
        { id: 3, src: assets.pepite },
        { id: 4, src: assets.cantine },
        { id: 5, src: assets.nu },
        { id: 6, src: assets.centrale }, // id adjusted to 6
    ];

    const sliderRef = useRef(null);

    useEffect(() => {
        const slider = sliderRef.current;

        const handleAnimationIteration = () => {
            // Manually handle resetting the position
            if (slider) {
                slider.style.transform = 'translateX(0)';
            }
        };

        slider.addEventListener('animationiteration', handleAnimationIteration);

        return () => {
            slider.removeEventListener('animationiteration', handleAnimationIteration);
        };
    }, []);

    return (
        <div className={`min-h-screen sm:min-h-min sm:py-20 ${styles.section} ${styles.bgWhite}`} id="partenaires">
            <div className='w-2/3 sm:w-full'>
                <h2 className={`mb-4 font-bold text-center ${styles.h1Text}`}>Partenaires</h2>
                <p className={`pb-20 sm:py-20 text-center sm:text-left ${styles.pText}`}>Nous croyons en <span className='font-bold text-secondary'>l'importance</span> de travailler ensemble pour créer des solutions innovantes pour les étudiants. Les collaborations avec nos partenaires nous permettent d'offrir une <span className='font-bold text-secondary'>expérience étudiante inégalée.</span></p>
                <div className="overflow-hidden relative fade-effect content-center">
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent to-LightGray z-10"></div>
                    <div className="absolute inset-0 bg-gradient-to-l from-transparent to-LightGray z-10"></div>
                    <div ref={sliderRef} className="flex whitespace-nowrap animate-slide z-0">
                        {[...partners, ...partners, ...partners].map((partner) => (
                            <img key={partner.id} src={partner.src} alt={partner.id} className="mx-8 w-64 h-32 object-contain" /> // Size adjusted here
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Partners;