import React, { useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import styles from '../styles/global';


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 border-b-2 border-Gray">
      <div
        className="flex justify-between items-center py-2 cursor-pointer transition-all duration-300 mb-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className={`text-lg font-medium ${styles.h2Text} ${styles.whiteText}`}>{question}</h2>
        <MdOutlineKeyboardArrowDown size={32}
          className={`transform transition-transform duration-300 text-xl ${isOpen && "rotate-180"} ${styles.whiteText}`}
        />
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-300 ${isOpen ? 'max-h-60' : 'max-h-0'}`}
      >
        <p className={`pl-2 mb-4 ${styles.answertext} ${styles.whiteText}`}>{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "Qu'est-ce que LSE ?",
      answer: "LSE est une plateforme numérique révolutionnaire dédiée à la vie universitaire. Elle centralise les ressources, les services et les opportunités essentielles pour les étudiants, le tout alimenté par l'intelligence artificielle."
    },
    {
      question: "Comment puis-je utiliser LSE ?",
      answer: "Pour utiliser LSE, il vous suffit de télécharger l'application sur votre appareil mobile et de créer un compte. C'est simple et rapide !"
    },
    {
      question: "Quelles sont les fonctionnalités de LSE ?",
      answer: "LSE offre un réseau social étudiant, un hub d'événements, une marketplace, une messagerie et bien plus encore. Découvrez tout ce que vous pouvez faire avec LSE."
    },
    {
      question: "L'application est-elle gratuite ?",
      answer: "Oui, LSE est entièrement gratuite pour les étudiants. Profitez de toutes ses fonctionnalités sans frais !"
    },
    {
      question: "Comment puis-je devenir partenaire de LSE ?",
      answer: "Si vous souhaitez devenir partenaire de LSE, contactez-nous à [votre adresse e-mail] pour discuter des opportunités de collaboration."
    },
    {
      question: "Comment puis-je soumettre des commentaires ou des suggestions ?",
      answer: "Nous adorons recevoir des commentaires de nos utilisateurs ! Utilisez le formulaire de feedback dans l'application pour nous faire part de vos idées et de vos suggestions."
    },

  ];

  return (
    <div className={`min-h-screen ${styles.section} ${styles.bgPrimary} scrollmargin`} id="FAQ">
      <div className="w-2/3 sm:w-full my-20 bg-primary scrollmargin">
        <div className="text-center mb-8">
          <h2 className={`${styles.whiteText} ${styles.h1Text} mb-8`}>Foire aux questions</h2>
          <p className={` ${styles.whiteText} ${styles.descriptionText} mt-2`}>Vous avez des questions ? Nous avons les réponses !</p>
        </div>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>

  );
};

export default FAQ;
