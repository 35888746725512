
import { Contact, SectionWrapper, FloatingNavbar, Footer, FAQ, Partners } from './components';
import assets from './assets';

const App = () => {
  return (
    <>

      <FloatingNavbar />
      <SectionWrapper
        title="La Sphère Étudiante"
        description={
          <>
            Découvrez une nouvelle façon de vivre votre
            <span className="font-bold text-secondary"> vie étudiante </span>
            avec La Sphère Étudiante. Notre application vous connecte à tout ce dont vous avez besoin.
          </>
        }
        showBtn
        mockupImg={assets.homeHero}
        banner="banner"
        isFirst={true}
      />
      <SectionWrapper
        title="Découvrez Nos Fonctionnalités Clés"
        description="Connectez-vous, découvrez, achetez, participez et accédez facilement aux services universitaires."
        mockupImg={assets.homeCards}
        reverse
        id="fonctions"
        isFirst={false}
      />
      <SectionWrapper
        title="La vie étudiante"
        description={
          <>La vie étudiante est riche en <span className="font-bold text-secondary"> opportunités</span>, en <span className="font-bold text-secondary">découvertes </span>et en <span className="font-bold text-secondary"> rencontres</span>. Cependant, elle peut aussi être complexe à gérer. C'est là que <span className="font-bold text-secondary">LSE</span> intervient. Notre plateforme vous donne accès à tout ce dont vous avez besoin pour profiter pleinement de votre <span className="font-bold text-secondary">vie universitaire.</span></>}
        mockupImg={assets.vieetudiante}

        id="vieétudiante"
        isFirst={false}
      />
      <FAQ />
      <Partners />
      {/*<SectionWrapper
        title="Partenaires"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
        mockupImg={assets.mockup}
        banner="banner02"

        isFirst={false}
  />*/}
      <Contact />

      <Footer />
      {/*<div className="px-4 py-2 justify-center items-center bg-primary flex-col text-center banner04">
        <p className={`${styles.pText} ${styles.whiteText}`}>LSE {" "}
        <span className="bold">2023</span>
        </p>
      </div>
    </>
  */}
    </>
  );
}

export default App;