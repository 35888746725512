import React from 'react';

import styles from '../styles/global';
import assets from '../assets';
import Button from './Button';
import Button2 from './Button2';

const SectionWrapper = ({ title, description, showBtn, mockupImg, banner, reverse, id, isFirst }) => {
  return (
    <div style={isFirst ? { paddingTop: '80px' } : {}} className={`min-h-screen ${styles.section} ${reverse ? styles.bgWhite : styles.bgPrimary} ${banner} `} id={id}>
      <div className={`flex items-center ${reverse ? styles.boxReverseClass : styles.boxClass} w-11/12 sm:w-full minmd:w-3/4 sm:gap-8`}>
        <div className={`${styles.descDiv} ${reverse ? " fadeRightMini" : " fadeLeftMini"} ${reverse ? styles.textRight : styles.textLeft} `}>
          <h1 className={`${reverse ? styles.blackText : styles.whiteText} ${styles.h1Text}`}>{title}</h1>
          <p className={`${reverse ? styles.blackText : styles.whiteText} ${styles.descriptionText} `}>{description}</p>
          <div className='grid grid-cols-2 gap-8 sm:grid-cols-1 justify-items-center'>
            {showBtn && (<Button assetUrl={assets.apple} link="https://apps.apple.com/us/app/la-sph%C3%A8re-etudiante/id6462524657" />)}
            {showBtn && (<Button2 assetUrl={assets.google} link="https://play.google.com/store/apps/details?id=com.lse.prod&pcampaignid=web_share" />)}
          </div>
        </div>
        <div className={`flex-1 ${styles.flexCenter}p-8 sm:px-0`}>
          <img src={mockupImg} alt="mockup" style={isFirst ? { paddingTop: '50px' } : {}} className={` ${reverse ? " fadeLeftMini" : " fadeRightMini"} ${styles.sectionImg}`} />
        </div>
      </div>
    </div>
  )
}

export default SectionWrapper