import React from 'react';
import styles from '../styles/global';
import Swal from 'sweetalert2';

const Contact = () => {
  
  const blackText = styles.blackText;
  const contactText = styles.contactText;
  const labelStyles = `block mb-2 text-sm font-medium ${contactText} ${blackText}`;

  function handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: 'Envoie en cours',
      showConfirmButton: false,
      timer: 800,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    }).then(() => {
      {
        Swal.fire({
          icon: 'success',
          title: 'Merci !',
          text: 'Message envoyé avec succès.',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
  }

  const InputField = ({ label, type }) => (
    <div className="mb-4">
      <label className={labelStyles}>{label}</label>
      <input className="w-full p-2 border-2 border-Gray rounded" type={type} placeholder="" />
    </div>
  );


  return (
    <div className={`${styles.bgWhite} scrollmargincontact`} id="contact">
      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-8 px-10 sm:px-0 pb-10 mx-10 sm:mx-8 rounded-lg ">
        <div className="col-span-1 mx-16 sm:mx-0 flex flex-col justify-center items-center h-full">
          <h1 className={`${blackText} ${styles.h1Text} sm:text-center`}>Contactez nous</h1>
          <p className={`${blackText} ${styles.descriptionText} text-center`}>Écrivez-nous ou appelez-nous 📞 <br />Nous vous répondrons dans les plus brefs délais.</p>
          <div className="grid sm:block grid-cols-2 justify-center text-center sm:contents">
            <a href="tel:+33751029864" className={`hover:text-secondary transition duration-300 ${blackText} ${styles.contactText} mb-2`}>(+33)7 51 02 98 64</a>
            <a href="mailto:contact@lasphereetudiante.com" className={`hover:text-secondary transition duration-300 ${blackText} ${styles.contactText}`}>contact@lasphereetudiante.com</a>
          </div>
        </div>
        <div className="col-span-1 mx-16 md:mx-4 lg:mx-32">
          <form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-1 grid-cols-2 gap-4 mb-4">
              <InputField label="Nom" type="text" />
              <InputField label="Prénom" type="text" />
            </div>
            <InputField label="Adresse mail" type="mail" />
            <InputField label="Objet" type="text" />
            <div className="mb-4">
              <label className={labelStyles}>Message</label>
              <textarea className="w-full p-2 border-2 border-Gray rounded" placeholder=""></textarea>
            </div>
            <div className="mb-4">
              <input type="checkbox" id="consent" className="mr-2" />
              <label htmlFor="consent" className={`text-sm ${styles.consentText} ${blackText}`}>J'accepte le traitement de mes données personnelles conformément à la Politique de Confidentialité *</label>
            </div>
            <div className="flex justify-center mt-8">
              <button className="p-2 px-8 bg-primary text-white rounded">Envoyer</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}

export default Contact;
