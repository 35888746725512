import React, { useState, useEffect } from 'react';
import styles from '../styles/global';
import { LuDownload } from 'react-icons/lu';
import assets from '../assets';

const FloatingNavbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      // Checking for showing/hiding behavior
      if (currentScrollPosition < lastScrollPosition) {
        // Scrolling up
        setShowNavbar(true);
      } else {
        // Scrolling down
        setShowNavbar(false);
      }

      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPosition]);

  return (
    <div className={`navbar floating ${showNavbar ? 'show' : 'hide'} hidden sm:hidden`}> {/* <-- Added Tailwind classes here */}
      <div className="navbar-left">
        <a href="#home" className="logo"><img src={assets.lsewhite} alt="LSE Logo" /></a>
      </div>
      <div className={`navbar-middle ${styles.navText}`}>
        <a href="#fonctions">Fonctionnalités</a>
        <a href="#vieétudiante">Vie étudiante</a>
        <a href="#FAQ">FAQ</a>
        <a href="#partenaires">Partenaires</a>
        <a href="#contact">Contact</a>
      </div>
      <div className="navbar-right">
        <button className="cta-button bg-secondary"><LuDownload size={24} /></button>
      </div>
    </div>
  );
}

export default FloatingNavbar;
