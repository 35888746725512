
import React from 'react';
import styles from '../styles/global';
import assets from '../assets';
import { LiaLinkedin, LiaInstagram } from 'react-icons/lia';

function Footer() {
  return (
    <div className={` ${styles.footerText} bg-primary pt-56 pb-8 px-8 grid grid-cols-3 sm:grid-cols-1 gap-12 sm:items-center justify-items-center banner04`}>

      {/* 1st Column: Social Media Icons */}
      <div className="flex justify-center">
        <div className="flex justify-evenly items-center space-x-4">
          <a href="https://www.instagram.com/la.sphere.etudiante/" target="_blank" rel="noreferrer">
            <LiaInstagram size={48} className="icon" color='white' />
          </a>
          <a href="https://www.linkedin.com/company/la-sph%C3%A8re-etudiante/" target="_blank" rel="noreferrer">
            <LiaLinkedin size={48} className="icon" color='white' />
          </a>
        </div>
      </div>

      {/* 2nd Column: Links */}
      <div className="grid grid-cols-2 gap-6">
        <div>
          <a href="#fonctions" className="block mb-2 text-white hover:underline">Fonctionnalités</a>
          <a href="#vieétudiante" className="block mb-2 text-white hover:underline">Vie étudiante</a>
          <a href="#FAQ" className="block mb-2 text-white hover:underline">Foire aux questions</a>
          <a href="#partenaires" className="block mb-2 text-white hover:underline">Partenaires</a>
        </div>
        <div>
          <a href="#" className="block mb-2 text-white hover:underline">Mentions légales</a>
          <a href="#" className="block mb-2 text-white hover:underline">Politiques de confidentialité</a>
          <a href="#" className="block mb-2 text-white hover:underline">Informations sur les cookies</a>
          <a href="#" className="block mb-2 text-white hover:underline">Conditions d'utilisation</a>
        </div>
      </div>

      {/* 3rd Column: Logo */}
      <div className="text-white flex justify-center">
        <img src={assets.lsewhite} className='max-h-40' alt="Logo" />
      </div>

    </div>
  );
}

export default Footer;
